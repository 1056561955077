<template>
  <el-card shadow="never" class="aui-card--fill" ref="element">
    <div>
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList">
        <el-form-item>
          <contract-cascader @change="contractChange"></contract-cascader>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.code" placeholder="清单编号" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.name" placeholder="清单名称" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group v-if="dataForm.contractId" style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList"></toolbar-button>
            <toolbar-button role="print" @click="openPrint">打印</toolbar-button>
            <toolbar-button role="export" @click="openExport">导出</toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <!-- <el-row :gutter="10">
        <el-col :span="24">
          <h1 class="my-report-title">合同清单对比分析</h1>
        </el-col>
      </el-row> -->
      <vxe-table
          border
          resizable
          show-overflow
          show-footer
          ref="tableRef"
          :data="dataList"
          :loading="dataListLoading"
          :height="tableHeight"
          :print-config="{}"
          :export-config="{}"
          :row-config="{isCurrent: true, isHover: true}"
          :cell-style="cellStyle">
        <vxe-column type="seq" title="序号" width="60" align="center"></vxe-column>
        <vxe-column field="code" title="清单编号" width="130"></vxe-column>
        <vxe-column field="name" title="清单名称" min-width="200"></vxe-column>
        <vxe-column field="typeId" title="清单类型" width="100" align="center" :formatter="typeFormatter"></vxe-column>
        <vxe-column field="unitName" title="单位" width="90" align="center"></vxe-column>
        <vxe-column field="price" title="单价(元)" width="120" align="right" :formatter="priceFormatter"></vxe-column>
        <vxe-colgroup field="group0" title="合同清单" align="center">
          <vxe-column field="initNum" title="数量" width="120" align="right" :formatter="numFormatter"></vxe-column>
          <vxe-column field="initAmount" title="金额(元)" width="120" align="right" :formatter="amountFormatter"></vxe-column>
        </vxe-colgroup>
        <vxe-colgroup field="group1" title="复核清单" align="center">
          <vxe-column field="checkedNum" title="数量" width="120" align="right" :formatter="numFormatter"></vxe-column>
          <vxe-column field="checkedAmount" title="金额(元)" width="120" align="right" :formatter="amountFormatter"></vxe-column>
        </vxe-colgroup>
        <vxe-colgroup field="group2" title="变更后清单" align="center">
          <vxe-column field="realNum" title="数量" width="120" align="right" :formatter="numFormatter"></vxe-column>
          <vxe-column field="realAmount" title="金额(元)" width="120" align="right" :formatter="amountFormatter"></vxe-column>
        </vxe-colgroup>
      </vxe-table>
    </div>
  </el-card>
</template>
<!--合同清单对比分析-->
<script>
import mixinViewModule from '@/mixins/view-module'
import XEUtils from "xe-utils";

export default {
  mixins: [mixinViewModule],
  data() {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/mps/boq/openapi/leafList',
        getDataListIsPage: false,
      },
      dataForm: {
        prjId: '',
        contractId: '',
        code: '',
        name: ''
      },
      contractAmountAccuracy: '', // 标段的金额精确度
      tableHeight: 400,

      // 打印顶部内容模板
      printTopHtml: `<h1 class="my-report-title">合同清单对比分析</h1>`,
      printConfig: {
        // 自定义打印的样式示例
        style: `.my-report-title {
                  text-align: center;
                }`,
        // 输出数据的方式列表 current:当前页数据、selected:选中数据、all:全量（所有页数据）
        modes: ['current'],
        // 打印之前
        beforePrintMethod: ({content}) => {
          // 拦截打印之前，返回自定义的 html 内容
          return this.printTopHtml + content;
        }
      },
      // 导出配置
      exportConfig: {
        modes: ['current']
      },
      // 设置单元格样式
      cellStyle({row, column}) {
        if (column.field == 'checkedNum' && row.initNum != row.checkedNum) {// 如果复核数量与原始数量不同，则突出显示
          return {
            color: '#DC143C'
          }
        } else if (column.field == 'realNum' && row.initNum != row.realNum) {// 如果变更后数量与原始数量不同，则突出显示
          return {
            color: '#DC143C'
          }
        } else if (column.field == 'typeId') {// 为不同的清单类型设置不同的颜色
          if (row.typeId == 1) {// 合同清单
            return {
              color: '#2E8B57'
            }
          } else if (row.typeId == 2 || row.typeId == 3) {// 变更清单
            return {
              color: '#FFC300'
            }
          } else if (row.typeId == 4) {// 暂定清单
            return {
              color: '#900C3F'
            }
          }
        }
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = window.innerHeight - 220;
    })
  },
  methods: {
    // 项目、标段切换事件
    contractChange(data) {
      this.dataForm.prjId = data.prjId
      this.dataForm.contractId = data.contractId
      this.contractAmountAccuracy = data.contract.amountAccuracy
      this.getDataList()
    },
    // vxeTable打印报表
    openPrint() {
      this.$refs.tableRef.openPrint(this.printConfig);
    },
    // vxeTable高级导出
    openExport() {
      this.$refs.tableRef.openExport(this.exportConfig);
    },
    // 金额格式化
    amountFormatter({cellValue}) {
      if (this.contractAmountAccuracy) {
        return XEUtils.commafy(cellValue, {digits: this.contractAmountAccuracy})
      } else {
        return XEUtils.commafy(cellValue)
      }
    },
    // 数量格式化
    numFormatter({cellValue, row}) {
      if (row.accuracy) {
        return XEUtils.commafy(cellValue, {digits: row.accuracy})
      } else {
        return XEUtils.commafy(cellValue)
      }
    },
    // 单价格式化
    priceFormatter({cellValue}) {
      return XEUtils.commafy(cellValue, {digits: 2})
    },
    // 清单类型格式化 清单类型： 1：合同清单（原始清单、复核清单），2：数量变更，3：设计变更（单价变更），4：暂定清单
    typeFormatter({cellValue}) {
      let typeName = ''
      if (cellValue == 1) {
        typeName = '合同清单'
      } else if (cellValue == 2 || cellValue == 3) {
        typeName = '变更清单'
      } else if (cellValue == 4) {
        typeName = '暂定清单'
      }
      return typeName
    },
  }
}
</script>
<style lang="scss" scoped>
.my-report-title {
  text-align: center;
}
</style>