<template>
  <el-card shadow="never" class="aui-card--fill">
    <my-container>
      <template v-slot:left>
        <el-form size="mini">
          <el-form-item>
            <contract-cascader @change="contractChange"></contract-cascader>
          </el-form-item>
        </el-form>
        <div class="tree-container">
          <el-tree ref="wbsTree" lazy
            class="left-tree"  node-key="id" v-loading="wbsTreeLoading"
            :data="wbsTreeData"
            :props="defaultProps"
            :load="loadWbsNode"
            :expand-on-click-node="false"
            @node-click="wbsTreeNodeClick"
            :highlight-current="true">
          </el-tree>
        </div>
      </template>
      <template v-slot:right>
        <div class="mod-__scheduleprogress">
          <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
            <el-form-item>
              <!-- 前一天 -->
              <el-tooltip content="前一天" placement="top-start" effect="light">
                <el-button @click="previousDayHandle" icon="el-icon-d-arrow-left" circle></el-button>
              </el-tooltip>
            </el-form-item>
            <el-form-item>
              <el-date-picker style="width: 170px" v-model="dataForm.reportDate" :format="dateFormat"
                  value-format="yyyy-MM-dd" type="date" placeholder="请选择上报日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <!-- 后一天 -->
              <el-tooltip content="后一天" placement="top-start" effect="light">
                <el-button @click="nextDayHandle" icon="el-icon-d-arrow-right" circle></el-button>
              </el-tooltip>
            </el-form-item>
            <el-form-item>
              <el-button-group v-if="dataForm.contractId" style="margin-top: -3px;">
                <toolbar-button role="add" v-if="$hasPermission('opm:scheduleprogress:save')" @click="addHandle()"></toolbar-button>
                <toolbar-button role="delete" v-if="$hasPermission('opm:scheduleprogress:delete')" @click="deleteHandle()"></toolbar-button>
                <toolbar-button name="查看甘特图" icon="el-icon-c-scale-to-original" @click="openGanttHandle()"></toolbar-button>
              </el-button-group>
            </el-form-item>
          </el-form>
          <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @sort-change="dataListSortChangeHandle" @selection-change="dataListSelectionChangeHandle" style="width: 100%;">
            <el-table-column type="selection" header-align="center" fixed="left" align="center" width="50"></el-table-column>
            <el-table-column prop="wbsName" label="分部分项" fixed="left" min-width="150" show-overflow-tooltip>
              <template slot-scope="scope">
                <span>{{ scope.row.wbsPnames?scope.row.wbsPnames+'，'+ scope.row.wbsName:scope.row.wbsName }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="wbsUnitName" label="单位" width="100" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
            <el-table-column prop="wbsStartDate" label="实际开工" width="120" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
            <el-table-column prop="wbsEndDate" label="实际完工" width="120" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
            <el-table-column prop="duration" label="工期(天)" width="100" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
            <el-table-column prop="percentage" label="施工进度" width="100" align="center" sortable="custom" show-overflow-tooltip>
              <template slot-scope="scope">
                <span>{{ scope.row.percentage * 100}} %</span>
              </template>
            </el-table-column>
            <el-table-column prop="reportDate" label="上报日期" width="120" align="center" sortable="custom" show-overflow-tooltip></el-table-column>
            <el-table-column :label="$t('handle')" fixed="right" header-align="center" align="center" width="140">
              <template v-slot="{row}">
                <table-button role="update" v-if="$hasPermission('opm:scheduleprogress:update')" @click="updateHandle(row.id)"></table-button>
                <table-button role="delete" v-if="$hasPermission('opm:scheduleprogress:delete')" @click="deleteHandle(row.id)"></table-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :current-page="page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="limit"
            :total="total"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="pageSizeChangeHandle"
            @current-change="pageCurrentChangeHandle">
          </el-pagination>
          <!-- 弹窗, 新增 / 修改 -->
          <my-batch-add v-if="addVisible" ref="myAdd" @refreshDataList="getDataList" @close="closeEditDialogHandle"></my-batch-add>
          <my-update v-if="updateVisible" ref="myUpdate" @refreshDataList="getDataList" @close="closeEditDialogHandle"></my-update>
          <!-- 甘特图 -->
          <my-gantt v-if="myGanttVisible" ref="myGantt" :showProgress="true"></my-gantt>
        </div>
      </template>
    </my-container>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import MyBatchAdd from './scheduleprogress-batch-add'
import MyUpdate from './scheduleprogress-update'
export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/opm/scheduleProgress/page',
        getDataListIsPage: true,
        deleteURL: '/opm/scheduleProgress',
      },
      dataForm: {
        prjId: '',
        contractId: '',
        wbsId: '',
        reportDate: '' // 上报日期
      },
      addVisible: false,
      updateVisible: false,

      wbsTreeLoading: false,
      wbsTreeData: [],
      defaultProps: {
        children: 'children',
        label: 'name',
        isLeaf: 'isLeaf'
      },
      dateFormat: 'yyyy年MM月dd日',
      myGanttVisible: false,
      // 甘特图配置
      tasks: {
        data: [],
      },
    }
  },
  watch: {
    // 上报日期变化
    'dataForm.reportDate' (newval,oldval) {
      this.getDataList()
    }
  },
  components: {
    MyBatchAdd,
    MyUpdate
  },
  methods: {
    // 合同标段切换事件
    contractChange (data) {
      this.dataForm.wbsId = ''
      this.dataForm.prjId = data.prjId
      this.dataForm.contractId = data.contractId
      this.getDataList()
      this.getWbsTreeData()
    },

    //异步加载wbs子节点数据
    loadWbsNode (node,resolve) {
      //此方法是进入页面后自动运行的,两个参数也是默认加载的
      if (node.level >= 1) {
        if (node && node.data) {
          let wbsData = node.data;
          if (wbsData.id == '-1') {
            return resolve([])
          } else {
            this.$http.get('/mps/wbs/children',
              {
                params: {
                  pid: wbsData.id,
                  contractId: wbsData.contractId,
                  subcontractorId: wbsData.subcontractorId
                }
              }
            ).then(({data: res}) => {
              if (res.code !== 0) {
                return this.$message.error(res.msg)
              }
              resolve(res.data);
            }).catch(() => {
              return this.$message.error('出错了')
            })
          }
        } else {
          return resolve([])
        }
      }
    },

    getWbsTreeData () {
      this.wbsTreeLoading = true
      // 清空wbs数据
      this.wbsTreeData = []
      this.$http.get('/mps/wbs/children',
        {
          params: {
            'pid': 0,
            'contractId': this.dataForm.contractId
          }
        }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.wbsTreeData = res.data || []
      }).catch(() => {
        return this.$message.error('出错了')
      }).finally(() => {
        this.wbsTreeLoading = false
      })
    },

    //  分部分项树节点点击事件
    wbsTreeNodeClick (data,node,ele) {
      // 点击高亮节点则取消高亮
      if (this.dataForm.wbsId == data.id) {
        this.dataForm.wbsId = ''
        this.$refs.wbsTree.setCurrentKey(null)
      } else {
        this.dataForm.wbsId = data.id
      }
      // 查询数据
      this.getDataList()
    },

    // 新增
    addHandle () {
      this.addVisible = true
      this.$nextTick(() => {
        this.$refs.myAdd.dataForm.contractId = this.dataForm.contractId
        this.$refs.myAdd.init()
      })
    },

    // 修改
    updateHandle (id) {
      this.updateVisible = true
      this.$nextTick(() => {
        this.$refs.myUpdate.dataForm.id = id
        this.$refs.myUpdate.init()
      })
    },

    closeEditDialogHandle () {
      this.addVisible = false
      this.updateVisible = false
    },

    // 前一天
    previousDayHandle () {
      let reportDate = this.getDate(this.dataForm.reportDate);
      reportDate = reportDate.setDate(reportDate.getDate() - 1)
      this.dataForm.reportDate = this.geDateStr(new Date(reportDate))
    },

    // 后一天
    nextDayHandle () {
      let reportDate = this.getDate(this.dataForm.reportDate);
      reportDate = reportDate.setDate(reportDate.getDate() + 1)
      this.dataForm.reportDate = this.geDateStr(new Date(reportDate))
    },

    // 获取日期字符串
    geDateStr (date) {
      var currentDate = date ? date : new Date();
      var year = currentDate.getFullYear().toString();
      var month = (currentDate.getMonth() + 1).toString();
      if (month.length === 1) {
        month = "0" + month;
      }
      var day = currentDate.getDate().toString();
      if (day.length === 1) {
        day = "0" + day;
      }
      return year + "-" + month + "-" + day
    },

    // 字符串转日期格式，strDate要转为日期格式的字符串
    getDate (strDate) {
      let date = new Date()
      if (strDate) {
        strDate = strDate.replace(/-/g,"/")
        date = new Date(strDate);
      }
      return date
    },
    // 打开甘特图组件
    openGanttHandle () {
      this.tasks.data = []
      // 查询周计划并组装为dhtmlx-gantt所需的数据格式
      this.$http.get('/opm/scheduleProgress/gantt',
        {
          params: {
            ...this.dataForm
          }
        }
      ).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.tasks.data = res.data || []
        this.myGanttVisible = true
        this.$nextTick(() => {
          this.$refs.myGantt.init(this.tasks)
        })
      }).catch(() => {
        return this.$message.error('出错了')
      })
    },
  }
}
</script>
<style scoped>
  .tree-container {
    width: 100%;
    overflow: auto;
    height: calc(100vh - 150px);
  }
</style>